<template>
  <b-row no-gutters>
    <b-col align="center">
      <b-avatar
        :text="title"
        :src="src"
        :badge-variant="values.isLocked ? 'danger' : ''"
        :class="values.isLocked ? 'border-danger' : ''"
        variant="profile"
        size="43"
      >
        <template #badge>
          <b-icon
            v-if="values.isLocked"
            icon="dash"
          >
          </b-icon>
        </template>
      </b-avatar>
    </b-col>
  </b-row>
</template>

<script>
  import {capitalize} from "@/utils/string";
  import {isNotUndefinedAndNotNull} from "@/utils/classes";
  export default {
    data: () => ({
      src: null,
    }),
    props: {
      photo: {
        type: Object,
        default: () => {}
      },
      values: {
        type: Object,
        default: () => {}
      }
    },
    computed: {
      title() {
        if (isNotUndefinedAndNotNull(this.values.firstName) && isNotUndefinedAndNotNull(this.values.lastName)) {
          const str1 = capitalize(this.values.firstName);
          const str2 = capitalize(this.values.lastName);
          return str1.charAt(0) + str2.charAt(0);
        } else {
          const array = this.values.fullName.split(' ');
          return array[0].charAt(0) + array[1].charAt(0);
        }
      }
    },
    created () {
      this.src = this.photo ? this.$filePath(this.photo.contentUrl) : null;
    },
    mounted() {
      $(".b-avatar-badge").css('right', '-7px');
    }
  }
</script>
<style scoped lang="scss">
@import "@lazy/_b-avatar";
</style>
